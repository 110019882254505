var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-us"},[_c('div',{staticClass:"Help-box"},[_c('div',{staticClass:"Help-content"},[_c('div',{staticClass:"contact-us-header"},[(_vm.nowElectron)?_c('h3',[_vm._v(_vm._s(_vm.getString(_vm.Strings.Setting_Up)))]):_vm._e(),_c('h3',[_vm._v(_vm._s(_vm.getString(_vm.Strings.Contact_Us_Message_Board)))])]),_c('div',{staticClass:"Help-seach"},[_c('div',{staticClass:"hblang"},[(_vm.nowElectron)?_c('div',{staticClass:"contact hb"},[_c('ul',[_c('li',{staticClass:"con-li"},[_vm._m(0),_c('p',{staticClass:"wz"},[_vm._v(_vm._s(_vm.getString(_vm.Strings.Contact_Us_Email))+": ")]),_vm._m(1)]),_c('li',{staticClass:"con-li"},[_vm._m(2),_c('p',{staticClass:"wz"},[_vm._v(_vm._s(_vm.getString(_vm.Strings.Microblog))+": ")]),_vm._m(3)]),_c('div',{staticClass:"con-div"},[_c('div',{staticClass:"condimg"},[_vm._m(4),_c('p',{staticClass:"offi-cial conzi"},[_vm._v(_vm._s(_vm.getString(_vm.Strings.WeChat)))])]),_c('div',{staticClass:"condimg"},[_vm._m(5),_c('p',{staticClass:"offi-cial conzi"},[_vm._v(_vm._s(_vm.getString(_vm.Strings.Contact_Us_No_Public)))])])])])]):_vm._e(),_c('div',{staticClass:"box-line"}),_c('div',{staticClass:"message-board hb"},[_c('div',[_c('a-form',{attrs:{"form":_vm.sendLeaveNote},on:{"submit":_vm.handleSendLeaveNote}},[_c('div',{staticClass:"shuang em"},[_c('a-input-group',{attrs:{"size":"large"}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'name',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: _vm.getString(_vm.Strings.Input_Username_isNull),
                                } ],
                            } ]),expression:"[\n                            'name',\n                            {\n                              rules: [\n                                {\n                                  required: true,\n                                  message: getString(Strings.Input_Username_isNull),\n                                },\n                              ],\n                            },\n                          ]"}],ref:"userNameInput",staticClass:"sleft",attrs:{"placeholder":_vm.getString(_vm.Strings.Contact_Us_Username)},on:{"input":function($event){return _vm.formValidation('name')}}})],1)],1),_c('a-col',{staticClass:"sright",attrs:{"span":12}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'email',
                            {
                              rules: [
                                {
                                  type: 'email',
                                  required: true,
                                  message: _vm.getString(_vm.Strings.Input_Email_isNull),
                                } ],
                            } ]),expression:"[\n                            'email',\n                            {\n                              rules: [\n                                {\n                                  type: 'email',\n                                  required: true,\n                                  message: getString(Strings.Input_Email_isNull),\n                                },\n                              ],\n                            },\n                          ]"}],ref:"userEmailInput",attrs:{"placeholder":_vm.getString(_vm.Strings.Contact_Us_Email)},on:{"input":function($event){return _vm.formValidation('email')}}})],1)],1)],1)],1),_c('div',{staticClass:"guestbook em"},[_c('a-form-item',[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'send',
                        {
                          rules: [
                            {
                              required: true,
                              message: _vm.getString(_vm.Strings.Input_Voicemail_isNull),
                            } ],
                        } ]),expression:"[\n                        'send',\n                        {\n                          rules: [\n                            {\n                              required: true,\n                              message: getString(Strings.Input_Voicemail_isNull),\n                            },\n                          ],\n                        },\n                      ]"}],staticStyle:{"resize":"none","text-indent":"2px","background":"#FBFBFB"},attrs:{"placeholder":_vm.getString(_vm.Strings.Contact_Us_Voicemail)+'...',"rows":12},on:{"input":function($event){return _vm.formValidation('send')}}})],1)],1),_c('a-form-item',[_c('div',{staticClass:"send-button em",class:_vm.CanBeSent?'can-sent':'no-sent'},[_c('a-button',{ref:"submitButton",attrs:{"html-type":"submit"}},[_vm._v(_vm._s(_vm.getString(_vm.Strings.Global_Ok)))])],1)])],1)],1)])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"conimg con-emailimg"},[_c('img',{attrs:{"src":require("../../assets/img/user/Iitem0selected/emails947.svg"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"conzi"},[_c('span',[_vm._v("yushu_mind@sina.com")]),_vm._v(" "),_c('span',[_vm._v("yushu_mind_support@yeah.net")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"conimg"},[_c('img',{attrs:{"src":require("../../assets/img/user/Iitem0selected/weibos948.svg"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"conzi"},[_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"https://weibo.com/u/7264879276","target":"_blank"}},[_vm._v("https://weibo.com/u/7264879276")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"cond-img"},[_c('img',{attrs:{"src":require("../../assets/img/user/Iitem0selected/weixinD.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"cond-img"},[_c('img',{attrs:{"src":require("../../assets/img/user/Iitem0selected/bjnews.png"),"alt":""}})])}]

export { render, staticRenderFns }